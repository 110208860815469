import React from "react";
import BeOurPartnerBanner from "../components/BeOurPartnerBanner";
import BeOurPartnerBenefits from "../components/BeOurPartnerBenefits";

function BeOurPartner() {
  return (
    <div className="beOurPartner">
      <BeOurPartnerBanner />
      <BeOurPartnerBenefits />
    </div>
  );
}

export default BeOurPartner;
