import * as React from "react";
import FavoritesComponent from "../components/FavoritesComponent";

import "../../assets/styles/Favorites.css";

function Favorites() {
  return (
    <div className="favorites">
      <FavoritesComponent />
    </div>
  );
}

export default Favorites;
